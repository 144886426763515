import React from "react";
import PropTypes from "prop-types";
import { Container } from "src/components/Base";
import classNames from "classnames";
import {
  cHasBackground,
  cHasBackgroundImage,
  cImageOverlay,
  cSection,
  cSmallPaddingBottom,
  cSmallPaddingTop,
} from "./styles.module.scss";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

const Section = ({
  children,
  hasBackground,
  className,
  smallPaddingBottom,
  smallPaddingTop,
  isBoxed,
  backgroundImage,
  id,
  containerClass,
}) => {
  let bgImage = null;

  if (backgroundImage) {
    bgImage = convertToBgImage(
      getImage(backgroundImage.localFile.childImageSharp)
    );
  }

  const sectionClasses = classNames({
    [cSection]: true,
    [cSmallPaddingBottom]: smallPaddingBottom,
    [cSmallPaddingTop]: smallPaddingTop,
    [cHasBackground]: hasBackground,
    [cHasBackgroundImage]: !!backgroundImage,
    [className]: !!className,
  });

  return (
    <>
      {!backgroundImage && (
        <section className={sectionClasses} id={id}>
          <Container isBoxed={isBoxed} className={containerClass}>
            {children}
          </Container>
        </section>
      )}

      {backgroundImage && (
        <BackgroundImage className={sectionClasses} Tag="section" {...bgImage}>
          <div className={cImageOverlay}></div>
          <Container isBoxed={isBoxed}>{children}</Container>
        </BackgroundImage>
      )}
    </>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasBackground: PropTypes.bool,
  backgroundColor: PropTypes.string,
  isBoxed: PropTypes.bool,
  backgroundImage: PropTypes.object,
  id: PropTypes.string,
};

Section.propTypes = {
  className: "test",
  hasBackground: false,
  backgroundColor: "#FF0000",
  isBoxed: false,
  backgroundImage: undefined,
  id: undefined,
};

export default Section;
