export const getLanguageFromUrl = () => {
  let currentLanguage = "de";

  if (typeof window !== `undefined`) {
    const url = window.location.pathname;
    const urlParts = url.split("/");

    if (urlParts.length >= 1) {
      if (urlParts[1] === "de") {
        currentLanguage = "de";
      }
      if (urlParts[1] === "en") {
        currentLanguage = "en";
      }
    }
  }

  return currentLanguage;
};

export const rewriteProjectUrl = (url, lang) => {
  let path = url;

  if (lang === "en" || lang === "EN") {
    path = url?.replace("/de/projekte/", "/en/projects/");
    path = path?.replace("/en/projekte/", "/en/projects/");
  }

  return path;
};
