// extracted by mini-css-extract-plugin
export var cAddress = "styles-module--c-address--8bb18";
export var cAddressElement = "styles-module--c-address-element--71d0f";
export var cBrandCol = "styles-module--c-brand-col--1ed7e";
export var cFooter = "styles-module--c-footer--57b09";
export var cFooterCol = "styles-module--c-footer-col--0385b";
export var cFooterHeader = "styles-module--c-footer-header--02551";
export var cFooterMenuItem = "styles-module--c-footer-menu-item--b6f76";
export var cFooterMenuItemHorizontal = "styles-module--c-footer-menu-item-horizontal--fa305";
export var cFooterMenuLink = "styles-module--c-footer-menu-link--03f73";
export var cFooterMenuList = "styles-module--c-footer-menu-list--a763a";
export var cFooterMenuListHorizontal = "styles-module--c-footer-menu-list-horizontal--17a1b";
export var cFooterSection = "styles-module--c-footer-section--a3c92";
export var cSocialBar = "styles-module--c-social-bar--58e05";
export var cSocialIcon = "styles-module--c-social-icon--9952e";
export var cSocialIconSvg = "styles-module--c-social-icon-svg--b89c7";
export var cSocket = "styles-module--c-socket--334f4";