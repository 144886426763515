import React, { useEffect, useRef, useState } from "react";
import { /* FlexboxRow as Row, */ Link } from "src/components/Base";
import MenuDropdown from "src/components/MenuDropdown";
import PropTypes from "prop-types";
// import LanguageSwitcher from "src/components/LanguageSwitcher";
import ReactHtmlParser from 'react-html-parser'

import {
  cActive,
  cDropdown,
  cLink,
  cList,
  cListElement,
  // cLangRow,
} from "./styles.module.scss";

// Outside Click Event Handler based on https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
function useOutsideAlerter(ref, setOpenDropDown) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenDropDown(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


const MainMenuDesktop = ({ data, className }) => {
    const [openDropDown, setOpenDropDown] = useState(null)
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, setOpenDropDown)


    return (
        <nav ref={wrapperRef} className={className}>
            <ul className={cList}>
                {data.map((item, index) => {

                const hasSublinks = item?.sublinks && item?.sublinks?.length > 0

                return (
                    <li key={item} className={cListElement}>
                         {hasSublinks ? (
                        <MenuDropdown
                        className={cDropdown}
                        dropdownItems={
                            item.sublinks && item.sublinks.length > 0
                            ? item.sublinks : []
                        }
                        isOpen={openDropDown === index}
                        index={index}
                        setOpenDropDown={setOpenDropDown}
                        >
                        {ReactHtmlParser(item?.link?.title)}
                        </MenuDropdown>
                    ) : (
                        <Link
                        to={item?.link?.url}
                        className={cLink}
                        activeClassName={cActive}
                        onClick={() => {
                            setOpenDropDown(null)
                        }}>
                          {ReactHtmlParser(item?.link?.title)}
                        </Link>
                    )}
                    </li>
                )
                })}
            </ul>
        </nav>
    )
}

MainMenuDesktop.propTypes = {
  data: PropTypes.array.isRequired,
};

export default MainMenuDesktop;
