// extracted by mini-css-extract-plugin
export var cMenu = "styles-module--c-menu--3fe38";
export var cMenuChevron = "styles-module--c-menu-chevron--e4c6a";
export var cMenuChevronActive = "styles-module--c-menu-chevron-active--9a179";
export var cMenuContainer = "styles-module--c-menu-container--f4c0a";
export var cMenuItem = "styles-module--c-menu-item--cddbd";
export var cMenuItems = "styles-module--c-menu-items--ddc1d";
export var cMenuLangRow = "styles-module--c-menu-lang-row--f7376";
export var cMenuLink = "styles-module--c-menu-link--1199c";
export var cMenuLinkActive = "styles-module--c-menu-link-active--a1a43";
export var cMenuLinkSub = "styles-module--c-menu-link-sub--34f9a";