import React from "react";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { Button, Text } from "src/components/Base";

import * as styles from "./styles.module.scss";

const CookieBanner = ({
  acceptAllCookies,
  toggleSettings,
  heading,
  text,
  buttonTextAccept,
  buttonTextSettings,
}) => (
  <div className={styles.cookiebanner}>
    <p className={styles.heading}>{heading}</p>
    <Text>{ReactHtmlParser(text)}</Text>
    <Button onClick={acceptAllCookies} className={styles.buttonAccept}>
      {buttonTextAccept}
    </Button>
    <Button
      color="secondary"
      onClick={toggleSettings}
      className={styles.buttonSettings}
    >
      {buttonTextSettings}
    </Button>
  </div>
);

CookieBanner.propTypes = {
  acceptAllCookies: PropTypes.func,
  toggleSettings: PropTypes.func,
};

export default CookieBanner;
