// extracted by mini-css-extract-plugin
export var cDark = "styles-module--c-dark--e007c";
export var cH1 = "styles-module--c-h1--c8c5d";
export var cH2 = "styles-module--c-h2--6c5bd";
export var cH3 = "styles-module--c-h3--5edf8";
export var cH4 = "styles-module--c-h4--1723e";
export var cH5 = "styles-module--c-h5--3fc7a";
export var cH6 = "styles-module--c-h6--17ecb";
export var cHeading = "styles-module--c-heading--f56f5";
export var cHeadingIsCentered = "styles-module--c-heading-is-centered--c6720";
export var cLight = "styles-module--c-light--7fd3a";
export var isCentered = "styles-module--is-centered--bd62e";