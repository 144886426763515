import React, { useEffect, useState } from 'react'
import { Container, Link } from 'src/components/Base'
import { v4 as uuid } from 'uuid'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ChevronIcon from 'src/assets/images/chevron-left.inline.svg'
import ReactHtmlParser from 'react-html-parser'

import {
  cMenu,
  cMenuChevron,
  cMenuChevronActive,
  cMenuContainer,
  cMenuItem,
  cMenuItems,
  cMenuLink,
  cMenuLinkActive,
  cMenuLinkSub,
} from './styles.module.scss'

const MainMenuMobile = ({ data, setMenuOpen }) => {
  const [navLinks, setNavLinks] = useState(data.filter((item) => item.link))
  const [navHeading, setNavHeading] = useState("")
  const [navParentLink, setNavParentLink] = useState('')
  const [menuLevel, setMenuLevel] = useState(0)

  const linkClasses = classNames({
    [cMenuLink]: true,
    [cMenuLinkSub]: !!navHeading,
  })

  const activeLinkClasses = classNames({
    [cMenuLink]: true,
    [cMenuLinkActive]: true,
  })

  const activeChevronClasses = classNames({
    [cMenuChevron]: true,
    [cMenuChevronActive]: true,
  })

  useEffect(() => {
    setNavLinks(navLinks)
  }, [navLinks])

  useEffect(() => {
    setNavHeading(navHeading)
  }, [navHeading])

  useEffect(() => {
    setNavParentLink(navParentLink)
  }, [navParentLink])

  return (
    <nav className={cMenu}>
      <Container className={cMenuContainer}>
        <ul className={cMenuItems}>
          {navHeading && (
            <li key={uuid()} className={cMenuItem}>
              <button
                className={activeLinkClasses}
                onClick={() => {
                  setNavLinks(data.filter((item) => item.link))
                  setNavHeading('')
                  setNavParentLink('')
                  setMenuLevel(0)
                }}
              >
                <ChevronIcon className={activeChevronClasses} />
                {ReactHtmlParser(navHeading)}
              </button>
            </li>
          )}
          {navLinks.map((item) => {
            const hasSublinks = item.sublinks && item.sublinks.length > 0

            // replace item with subitem data if menuLevel is > 0
            const title = menuLevel === 0 ? item.link.title : item?.sublink?.title
            const url = menuLevel === 0 ? item.link.url : item?.sublink?.url

            return title && url ? (
              <li key={item} className={cMenuItem}>
                {hasSublinks ? (
                  <button
                    className={linkClasses}
                    onClick={() => {
                      setNavLinks(item.sublinks)
                      setNavHeading(item.link.title)
                      setNavParentLink(item.link.url)
                      setMenuLevel(1)
                    }}
                  >
                     {ReactHtmlParser(item.link.title)}
                  </button>
                ) : (
                  <Link
                    to={url}
                    className={linkClasses}
                    onClick={() => {
                      setMenuOpen(false)
                    }}
                  >
                    {ReactHtmlParser(title)}
                  </Link>
                )}
              </li>
            ) : null
          })}
        </ul>
      </Container>
    </nav>
  )
}

MainMenuMobile.propTypes = {
  data: PropTypes.array.isRequired,
  setMenuOpen: PropTypes.function,
}

export default MainMenuMobile
