import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  cAsterisk,
  cCheckbox,
  cError,
  cErrorMessage,
  cInput,
  cLabel,
  cPrimary,
  cSecondary,
  cSuccess,
  cTouched,
} from "./styles.module.scss";

const Input = ({
  as: Component = "input",
  className,
  labelClassName,
  errorClassName,
  type,
  color,
  label,
  error,
  success,
  required,
  register,
  name,
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const buttonClasses = classNames({
    [cInput]: true,
    [cError]: !!error,
    [cSuccess]: !!success,
    [cPrimary]: color === "primary",
    [cSecondary]: color === "secondary",
  });

  const labelClasses = classNames({
    [cLabel]: true,
    [cTouched]: touched,
    [labelClassName]: !!labelClassName,
  });

  const errorClasses = classNames({
    [cErrorMessage]: true,
    [errorClassName]: !!errorClassName,
  });

  const wrapperClasses = classNames({
    [cCheckbox]: type === "checkbox",
    [className]: !!className,
  });

  return (
    <div className={wrapperClasses}>
      {!!label && (
        <label className={labelClasses} htmlFor="">
          {label}
          {required && <span className={cAsterisk}> *</span>}
        </label>
      )}
      <Component
        onFocus={() => setTouched(true)}
        className={buttonClasses}
        type={type}
        required={required}
        {...register(name)}
        {...props}
      />
      {!!error && <p className={errorClasses}>{error}</p>}
    </div>
  );
};

Input.PropTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.node.isRequired,
  register: PropTypes.func,
  name: PropTypes.string,
};

Input.defaultProps = {
  as: "input",
  className: "",
  labelClassName: "",
  errorClassName: "",
  type: "text",
  color: "",
  label: "",
  error: "",
  disabled: false,
  register: undefined,
  name: "",
};

export default Input;
