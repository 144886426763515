import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import Cookies from "js-cookie";
import CookieBanner from "./Banner";
import CookieSettings from "./Settings";
import { triggerTagManagerEvent } from "src/utils/gtm.js";
import LanguageContext from "src/context/LanguageContext";

const CookieConsent = ({ cookieName, cookieSettingsDefaultCookieText }) => {
  const data = useStaticQuery(graphql`
    query cookieNoticeQuery {
      wp {
        themeGeneralSettings {
          cookieNotice {
            cookies {
              cookieName
              infoText
              cookieNameEn
              infoTextEn
            }
            cookieBanner {
              acceptButton
              bannerText
              cookieSettingsButton
              heading
              acceptCookiesTextEn
              bannerTextEn
              cookieSettingsButtonEn
              headingEn
            }
            cookieSettings {
              acceptAllButton
              essentialCookiesText
              essentialCookiesInfoText
              rejectAllButton
              saveSettingsButton
              settingsHeader
              settingsText
              acceptAllButtonEn
              essentialCookiesTextEn
              essentialCookiesInfoTextEn
              rejectAllButtonEn
              saveSettingsButtonEn
              settingsHeaderEn
              settingsTextEn
              cookieGuidelinesLink {
                title
                target
                url
              }
              cookieGuidelinesLinkEn {
                title
                target
                url
              }
            }
          }
        }
      }
    }
  `);

  const languageContext = useContext(LanguageContext);

  const cookieBanner = data.wp.themeGeneralSettings.cookieNotice.cookieBanner;
  const cookieSettings =
    data.wp.themeGeneralSettings.cookieNotice.cookieSettings;

  const [isBannerVisible, setBannerVisible] = useState(
    !Cookies.get(cookieName)
  );
  const [isSettingVisible, setSettingVisible] = useState(false);
  const gtmTriggers = data.wp.themeGeneralSettings.cookieNotice.cookies;

  const init = () => {
    Cookies.set(cookieName, true, { expires: 14 });
    Cookies.set("gatsby-gdpr-google-tagmanager", true);
  };

  const acceptAllCookies = () => {
    init();
    Cookies.set("gatsby-gdpr-google-tagmanager", true, { expires: 14 });
    hideUI();
    location.reload();
  };

  const rejectAllCookies = () => {
    Cookies.set(cookieName, false, { expires: 1 });
    Cookies.set("gatsby-gdpr-google-tagmanager", false);
    hideUI();
  };

  const saveAndExitSettings = (events) => {
    init();
    if (
      events.includes("targetingMarketing") ||
      events.includes("userExperience")
    ) {
      Cookies.set("gatsby-gdpr-google-tagmanager", true, { expires: 14 });
    } else {
      Cookies.set("gatsby-gdpr-google-tagmanager", false);
    }
    hideUI();
    location.reload();
  };

  const triggerEvents = (events) => {
    events.forEach((event) => {
      triggerTagManagerEvent(event);
    });
  };

  const toggleSettings = () => {
    setBannerVisible(false);
    setSettingVisible(!isSettingVisible);
  };

  const hideUI = () => {
    setSettingVisible(false);
    setBannerVisible(false);
  };

  const checkCookie = () => {
    const events = Cookies.get(`${cookieName}-events`);
    if (events) {
      triggerEvents(JSON.parse(events));
    }
  };

  useEffect(() => {
    checkCookie();
  });

  return (
    <>
      {isBannerVisible && (
        <CookieBanner
          acceptAllCookies={acceptAllCookies}
          toggleSettings={toggleSettings}
          eading={
            languageContext.currentLanguage === "en"
              ? cookieBanner.headingEn
              : cookieBanner.heading
          }
          text={
            languageContext.currentLanguage === "en"
              ? cookieBanner.bannerTextEn
              : cookieBanner.bannerText
          }
          buttonTextAccept={
            languageContext.currentLanguage === "en"
              ? cookieBanner.acceptCookiesTextEn
              : cookieBanner.acceptButton
          }
          buttonTextSettings={
            languageContext.currentLanguage === "en"
              ? cookieBanner.cookieSettingsButtonEn
              : cookieBanner.cookieSettingsButton
          }
        />
      )}
      {isSettingVisible && (
        <CookieSettings
          acceptAllCookies={acceptAllCookies}
          toggleSettings={toggleSettings}
          rejectAllCookies={rejectAllCookies}
          cookies={gtmTriggers}
          saveAndExitSettings={saveAndExitSettings}
          heading={
            languageContext.currentLanguage === "en"
              ? cookieSettings.settingsHeadingEn
              : cookieSettings.settingsHeading
          }
          text={
            languageContext.currentLanguage === "en"
              ? cookieSettings.settingsTextEn
              : cookieSettings.settingsText
          }
          acceptAllButtonText={
            languageContext.currentLanguage === "en"
              ? cookieSettings.acceptAllButtonEn
              : cookieSettings.acceptAllButton
          }
          rejectAllButtonText={
            languageContext.currentLanguage === "en"
              ? cookieSettings.rejectAllButtonEn
              : cookieSettings.rejectAllButton
          }
          saveButtonText={
            languageContext.currentLanguage === "en"
              ? cookieSettings.saveSettingsButtonEn
              : cookieSettings.saveSettingsButton
          }
          defaultCookiesText={cookieSettingsDefaultCookieText}
          defaultCookiesInfoText={
            languageContext.currentLanguage === "en"
              ? cookieSettings.essentialCookiesInfoTextEn
              : cookieSettings.essentialCookiesInfoText
          }
          cookieGuidelinesLink={
            languageContext.currentLanguage === "en"
              ? cookieSettings.cookieGuidelinesLinkEn
              : cookieSettings.cookieGuidelinesLink
          }
        />
      )}
    </>
  );
};

CookieConsent.propTypes = {
  cookieName: PropTypes.string,
  gtmTriggers: PropTypes.array,
};

CookieConsent.defaultProps = {
  cookieName: "cookie-banner",
  gtmTriggers: [],
};

export default CookieConsent;
