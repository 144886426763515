// extracted by mini-css-extract-plugin
export var body = "styles-module--body--fbec9";
export var buttonAccept = "styles-module--button-accept--7b208";
export var buttonReject = "styles-module--button-reject--f56f9";
export var buttons = "styles-module--buttons--40b28";
export var checkbox = "styles-module--checkbox--fd38c";
export var checkboxCheck = "styles-module--checkbox-check--07f76";
export var checkboxWrapper = "styles-module--checkbox-wrapper--cf592";
export var circle = "styles-module--circle--2ed5a";
export var cookie = "styles-module--cookie--031fb";
export var cookieSwitch = "styles-module--cookie-switch--9ea87";
export var cookieSwitchEssential = "styles-module--cookie-switch-essential--dd97a";
export var cookieText = "styles-module--cookie-text--f4378";
export var cookiesCheckbox = "styles-module--cookies-checkbox--f4b5f";
export var cookiesHeader = "styles-module--cookies-header--0fad4";
export var cookiesLink = "styles-module--cookies-link--1c755";
export var cookiesettings = "styles-module--cookiesettings--607fb";
export var footer = "styles-module--footer--827f5";
export var formGroup = "styles-module--form-group--fb8a6";
export var header = "styles-module--header--79832";
export var heading = "styles-module--heading--f96fd";
export var labelCheck = "styles-module--label-check--fd95f";
export var labelSwitch = "styles-module--label-switch--baeb3";
export var modal = "styles-module--modal--34df1";
export var row = "styles-module--row--ddeb7";
export var save = "styles-module--save--379ae";
export var text = "styles-module--text--ff0dd";