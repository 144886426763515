import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Section, Container, Link } from "src/components/Base"
import {
  cBar,
  cBarContent,
  cBarTel,
  cBarLink,
  cBarIcon,
} from "./styles.module.scss"

// Icons
import Phone from "src/assets/images/phone.svg"
import CustomerSupport from "src/assets/images/customer-support.svg"

const Topbar = () => {
  const data = useStaticQuery(graphql`
    query TopbarQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            belluttiTopbar {
              fieldGroupName
              topbarphone
              topbarlink {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const { belluttiTopbar } = data.allWp.nodes[0].themeGeneralSettings

  return (
    <Section className={cBar}>
      <Container className={cBarContent}>
        {belluttiTopbar?.topbarphone && (
          <a href={`tel:${belluttiTopbar?.topbarphone}`} className={cBarTel}>
            <img src={Phone} alt="tel" className={cBarIcon} />
            {belluttiTopbar?.topbarphone}
          </a>
        )}
        {belluttiTopbar?.topbarlink?.url && (
          <Link to={belluttiTopbar?.topbarlink?.url} className={cBarLink}>
            <img
              src={CustomerSupport}
              alt="customer-support"
              className={cBarIcon}
            />
            {belluttiTopbar?.topbarlink?.title}
          </Link>
        )}
      </Container>
    </Section>
  )
}

export default Topbar
