// extracted by mini-css-extract-plugin
export var col = "styles-module--col--59648";
export var offset1 = "styles-module--offset1--a4c1f";
export var offset10 = "styles-module--offset10--c92b1";
export var offset11 = "styles-module--offset11--8f6cc";
export var offset12 = "styles-module--offset12--f7482";
export var offset2 = "styles-module--offset2--8e8a1";
export var offset3 = "styles-module--offset3--2e2d4";
export var offset4 = "styles-module--offset4--88e1d";
export var offset5 = "styles-module--offset5--967bd";
export var offset6 = "styles-module--offset6--0df00";
export var offset7 = "styles-module--offset7--3a5ee";
export var offset8 = "styles-module--offset8--594fa";
export var offset9 = "styles-module--offset9--c354c";
export var width1 = "styles-module--width1--db26e";
export var width10 = "styles-module--width10--677e5";
export var width11 = "styles-module--width11--5ae3a";
export var width12 = "styles-module--width12--907ad";
export var width2 = "styles-module--width2--c9203";
export var width3 = "styles-module--width3--63a67";
export var width4 = "styles-module--width4--734fe";
export var width5 = "styles-module--width5--13330";
export var width6 = "styles-module--width6--698e3";
export var width7 = "styles-module--width7--860d9";
export var width8 = "styles-module--width8--c45e4";
export var width9 = "styles-module--width9--4606c";