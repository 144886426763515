import React, { createContext, useState } from "react";

const defaults = {
  locations: [],
};

const LocationContext = createContext(defaults);

export const LocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState(defaults.locations);

  return (
    <LocationContext.Provider value={{ currentLocation, setCurrentLocation }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
