import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  Container,
  FlexboxCol,
  FlexboxRow,
  Heading,
  Link,
  Section,
  Text,
} from "src/components/Base";
import Brand from "src/assets/images/brand-white.inline.svg";
import FacebookIcon from "src/assets/images/facebook.inline.svg";
import InstagramIcon from "src/assets/images/instagram.inline.svg";
import LinkedinIcon from "src/assets/images/linkedin.inline.svg";
import { v4 as uuid } from "uuid";
import LanguageContext from "src/context/LanguageContext";
import ReactHtmlParser from 'react-html-parser'

import {
  cAddress,
  cAddressElement,
  cBrandCol,
  cFooter,
  cFooterCol,
  cFooterHeader,
  cFooterMenuItem,
  cFooterMenuItemHorizontal,
  cFooterMenuLink,
  cFooterMenuList,
  cFooterMenuListHorizontal,
  cFooterRow,
  cFooterSection,
  cSocialBar,
  cSocialBarIcon,
  cSocialIcon,
  cSocialIconSvg,
  cSocket,
  cSocketMenu,
} from "./styles.module.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterMenuQuery {
      allWp {
        nodes {
          themeGeneralSettings {
            belluttiMenu {
              fieldGroupName
                footerMenu1De {
                    fieldGroupName
                    links {
                    fieldGroupName
                        link {
                            target
                            title
                            url
                        }
                    }
                }
                footerMenu2De {
                    fieldGroupName
                    links {
                    fieldGroupName
                        link {
                            target
                            title
                            url
                        }
                    }
                }
                socketDe {
                  fieldGroupName
                  links {
                    fieldGroupName
                    link {
                      target
                      title
                      url
                    }
                  }
                }
            }
            socialMedia {
              optionFacebookUrl
              optionInstagramUrl
              optionLinkedinUrl
            }
            websitenbetreiber {
              impressumAddress
              impressumCity
              impressumCompany
              impressumCountry
              impressumEmail
              impressumPhone
              impressumZip
            }
            plakativStringTranslations {
              stringTranslationFooterCol1
              stringTranslationFooterCol2
              stringTranslationFooterCol1En
              stringTranslationFooterCol2En
            }
          }
        }
      }
      allWpMenu {
        nodes {
          name
          menuItems {
            nodes {
              id
              label
              url
            }
          }
        }
      }
    }
  `);

  const { socialMedia, websitenbetreiber, plakativStringTranslations } =
    data.allWp.nodes[0].themeGeneralSettings;

  const languageContext = useContext(LanguageContext);

  const {
    footerMenu1De,
    footerMenu2De,
    socketDe,
  } = data?.allWp?.nodes[0]?.themeGeneralSettings?.belluttiMenu

  const [footerMenuCol1, setFooterMenuCol1] = useState(footerMenu1De)
  const [footerMenuCol2, setFooterMenuCol2] = useState(footerMenu2De)
  const [socket, setSocket] = useState(socketDe)

  useEffect(() => {
    setFooterMenuCol1(footerMenu1De)
    setFooterMenuCol2(footerMenu2De)
    setSocket(socketDe)
  }, [languageContext.currentLanguage])

  const renderMenu = (
    name,
    horizontal = false
  ) => {

    const menuList = name?.links?.map((item) => {

      const title = item?.link?.title ?? null

      return title ? (
        <li key={uuid()} className={horizontal ? cFooterMenuItemHorizontal : cFooterMenuItem}>
          <Link
            className={cFooterMenuLink}
            to={item.link.url}
            target={item.link.target}
          >
            {ReactHtmlParser(title)}
          </Link>
        </li>
      ) : null
    })

    return (
      <ul className={horizontal ? cFooterMenuListHorizontal : cFooterMenuList}>
        {menuList}
      </ul>
    )
  }

  return (
    <footer className={cFooter}>
      <Section className={cFooterSection}>
        <FlexboxRow className={cFooterRow}>
          <FlexboxCol className={cFooterCol} width={6}>
            <div className={cBrandCol}>
              <Brand />
              <ul className={cSocialBar}>
                <li className={cSocialBarIcon}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cSocialIcon}
                    href={socialMedia.optionFacebookUrl}
                  >
                    <FacebookIcon className={cSocialIconSvg} />
                  </a>
                </li>
                <li className={cSocialBarIcon}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cSocialIcon}
                    href={socialMedia.optionInstagramUrl}
                  >
                    <InstagramIcon className={cSocialIconSvg} />
                  </a>
                </li>
                <li className={cSocialBarIcon}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cSocialIcon}
                    href={socialMedia.optionLinkedinUrl}
                  >
                    <LinkedinIcon className={cSocialIconSvg} />
                  </a>
                </li>
              </ul>
            </div>
          </FlexboxCol>
          <FlexboxCol className={cFooterCol} width={3}>
            <Heading className={cFooterHeader} color="light" size={4} as="h4">
              {languageContext.currentLanguage === "en"
                ? plakativStringTranslations.stringTranslationFooterCol1En
                : plakativStringTranslations.stringTranslationFooterCol1}
            </Heading>
            {footerMenuCol1?.links && (
              <>
                {renderMenu(footerMenuCol1, false)}
              </>
            )}
          </FlexboxCol>
          <FlexboxCol className={cFooterCol} width={3}>
            <Heading className={cFooterHeader} color="light" size={4} as="h4">
              {languageContext.currentLanguage === "en"
                ? plakativStringTranslations.stringTranslationFooterCol2En
                : plakativStringTranslations.stringTranslationFooterCol2}
            </Heading>
            {footerMenuCol2?.links && (
              <>
                {renderMenu(footerMenuCol2, false)}
              </>
            )}
          </FlexboxCol>
        </FlexboxRow>
        <Container>
          <div className={cSocket}>
            <div className={cAddress}>
              <Text as="span" className={cAddressElement}>
                {websitenbetreiber.impressumCompany}
              </Text>
              <Text
                as="span"
                className={cAddressElement}
              >{`${websitenbetreiber.impressumAddress}, ${websitenbetreiber.impressumZip} ${websitenbetreiber.impressumCity}, ${websitenbetreiber.impressumCountry}`}</Text>
              <Text as="span" className={cAddressElement}>
                {websitenbetreiber.impressumPhone}
              </Text>
              <Text as="span" className={cAddressElement}>
                {websitenbetreiber.impressumEmail}
              </Text>
            </div>
            <nav className={cSocketMenu}>{renderMenu(socket, true)}</nav>
          </div>
        </Container>
      </Section>
    </footer>
  );
};

export default Footer;
